
import 'vidstack/icons';
import 'mapbox-gl/dist/mapbox-gl.css';
import './css/main.css';

import barba from '@barba/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);





barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
        {
            name: 'opacity-transition',
            leave(data) {
                return gsap.to(data.current.container, {
                    opacity: 0
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    opacity: 0
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        },
        {
            name: 'swipeFromRight-transition',
            from: {
                // define a custom rule based on the trigger class
                custom: ({ trigger }) => {
                  return trigger.classList && trigger.classList.contains('swipeFromRight');
                },
            },
            leave(data) {
                return gsap.to(data.current.container, {
                    x: '-100%'
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    x: '100%',
                    y: 0
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        },
        {
            name: 'swipeFromLeft-transition',
            from: {
                // define a custom rule based on the trigger class
                custom: ({ trigger }) => {
                  return trigger.classList && trigger.classList.contains('swipeFromLeft');
                },
            },
            leave(data) {
                return gsap.to(data.current.container, {
                    x: '100%'
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    x: '-100%'
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        }
    ],
});


function scrollToTop() {
// window.scrollTo({top: 0, behavior: 'smooth'});
window.scrollTo({top: 0});
};

function removeContStyle(data) {
setTimeout(function() {data.next.container.setAttribute('style', '')}, 1000);
}

function setColour(data) {
    import(/* webpackChunkName: "setColour" */ "./js/components/setColour.js").then(module => {
        const colourit = module.default;
        colourit(data);
    });
}


function bgVidCheck() {
    let bgVid = document.getElementsByClassName('talentPlayVid');
    if (bgVid.length > 0) {
        import(/* webpackChunkName: "bgVid" */ "./js/components/bgVid.js").then(module => {
            const bgVid = module.default;
            bgVid();
        });
    };
}

function anchorCheck() {
    let anchors = document.getElementsByClassName('anchorLink');
    if (anchors.length > 0) {
        import(/* webpackChunkName: "anchorLink" */ "./js/components/anchorLinks.js").then(module => {
            const anGo = module.default;
            anGo();
        });
    };
}



function hamburgerToggle(preset) {
    import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
        const toggle = module.default;
        toggle(preset);
    });
}


function playerCheck(data) {
    import(/* webpackChunkName: "vidPlayer" */ "./js/components/vidPlayer.js").then(module => {
        const vidIt = module.default;
        vidIt(data, barba)
    });
}


function vidReplay(data) {
    if (data.next.namespace == 'video') {
        let player = document.querySelector('media-player');
        player.addEventListener('ended', (event) => {
            // let upNextDiv = document.getElementById('upNext');
            // upNextDiv.classList.remove('hidden');
            let nextButton = document.getElementsByClassName('playNextButton');
            if (nextButton.length > 0) {
                let timer = setInterval(() => {
                    let nextUrl = nextButton[0].getAttribute('data-next');
                    // window.location.href = nextUrl;
                    barba.go(nextUrl, 'swipeFromRight-transition');
                    clearInterval(timer);
                }, 8000);
                let replayButton = document.getElementById('replayButton');
                replayButton.addEventListener('click', (event) => {
                    clearInterval(timer)
                })
                let playNextLink = document.querySelector('#playNextButtonLink');
                playNextLink.addEventListener('click', () => {
                    clearInterval(timer);
                })
            }
        })
    } 
}

let hamburgerOuter = document.getElementById("hamburgerOuter");
hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});

let menuCloser = document.getElementById('dropdownCloser');
menuCloser.addEventListener("click", () => {hamburgerToggle('c')});



function videos() {
    let vidPlayers = document.querySelectorAll('media-player');
    if (vidPlayers.length > 0) {
        let vd;
        for (vd=0; vd < vidPlayers.length; vd++) {
            vidPlayers[vd].addEventListener('provider-change', (event) => {
                const provider = event.detail;
                if (provider?.type === 'hls') {
                    console.log('HLS config change');
                    provider.config = { startLevel: 5 };
                };
            });
        };
    };
};


function logoScale() {
    let theLogo = document.getElementById('theLogo');
    ScrollTrigger.create({
        trigger: 'body',
        start: 'top 5px',
        end: '+=100px',
        // onEnter: (self) => thebub.classList.remove('-translate-y-[120%]'),
        // onLeave: (self) => thebub.classList.add('-translate-y-[120%]'),
        onToggle: (self) => theLogo.classList.toggle('scale-125'),
        // markers: {startColor: "black", endColor: "black", fontSize: "18px", fontWeight: "bold", indent: 20}
    })
}

function photCheck(data) {
    let photGrid = document.getElementsByClassName('photGrid');
    if (photGrid.length > 0) {
        import(/* webpackChunkName: "photoGrid" */ "./js/components/photoGrid.js").then(module => {
            const photoIt = module.default;
            photoIt(data)
        });
    }
}

function galleryCheck() {
    let galleries = document.getElementsByClassName('gallery');
    if (galleries.length > 0) {
      import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
        const galleryGo = module.default;
        galleryGo();
      });
    };
};

function villageCheck() {
    let villageOverlay = document.getElementsByClassName('villageOverlay');
    if (villageOverlay.length > 0) {
      import(/* webpackChunkName: "villageOverlay" */ "./js/components/villageOverlay.js").then(module => {
        const villageGo = module.default;
        villageGo();
      });
    };
};

function mapCheck() {
    let maps = document.getElementsByClassName('map');
    if (maps.length > 0) {
        import(/* webpackChunkName: "map" */ "./js/components/map.js").then(module => {
            const mapGo = module.default;
            mapGo();
          });
    }
}

function splashCheck() {
    let splash = document.getElementsByClassName('splashScreen');
    if (splash.length > 0) {
        import(/* webpackChunkName: "splash" */ "./js/components/splash.js").then(module => {
            const splashGo = module.default;
            splashGo();
          });
    }
}

function flipperCheck(splasher) {
    let flippers = document.getElementsByClassName('flipper');
    let splash = document.getElementsByClassName('splashScreen');
    let delay;
    if (splash.length > 0 && splasher == false) {
        delay = (splash[0].getAttribute('data-splashlength')) * 1000;
    } else {
        delay = 0;
    };

    if (flippers.length > 0) {
        import(/* webpackChunkName: "flipper" */ "./js/components/flipper.js").then(module => {
            const flipperGo = module.default;
            flipperGo(delay);
          });
    }
}

barba.hooks.once((data) => {
    splashCheck();
    videos();
    setColour(data);
    bgVidCheck();
    anchorCheck();
    logoScale();
    playerCheck(data);
    vidReplay(data);
    photCheck(data);
    galleryCheck();
    mapCheck();
    villageCheck();
    flipperCheck(false);
});

barba.hooks.afterOnce((data) => {

});

barba.hooks.before((data) => {
    hamburgerToggle('c');
})

barba.hooks.beforeEnter((data) => {
    scrollToTop();
    videos();
});


barba.hooks.after((data) => {
    setColour(data);
    bgVidCheck();
    anchorCheck();
    playerCheck(data);
    vidReplay(data);
    photCheck(data);
    galleryCheck();
    mapCheck();
    villageCheck();
    flipperCheck(true);
});
